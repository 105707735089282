
const localStorage = window.localStorage;

function updateTracking () {
  if (window && window.location && localStorage) {
    // Capture the first page visited
    const initialPage = localStorage.getItem('initialPage');
    if (!initialPage) {
      localStorage.setItem('initialPage', window.location.href);
    }

    // Capture campaigns via the keywords query param
    if (window.location.search && typeof URLSearchParams === 'function') {
      const queryParams = new URLSearchParams(window.location.search);
      const keyword = queryParams.get('keyword');

      if (keyword) {
        let keywords = localStorage.getItem('keywords');

        if (keywords) {
          keywords = keywords.split(',');
          if (!keywords.includes(keyword)) {
            keywords.push(keyword);
          }
          keywords = keywords.join(',');
        } else {
          keywords = keyword;
        }

        localStorage.setItem('keywords', keywords);
      }
    }

    // Capture visits to stack.new
    if (window.location.pathname.startsWith('/registry')) {
      const registry = localStorage.getItem('registry');

      if (!registry) {
        localStorage.setItem('registry', true);
        localStorage.setItem('registryLanding', window.location.href);
      }
    }
  } else {
    // Squash errors
  }
}

async function sleep (ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

// There isn't a great universal event based method to detect changes to the location
// so we have to go with polling
async function startTracking () {
  let currentPage = window.location.href;
  while (true) {
    if (currentPage !== window.location.href) {
      updateTracking();
      currentPage = window.location.href;
    }
    await sleep(1000);
  }
}

updateTracking();
startTracking();
