/* eslint-disable no-unused-vars */
// Bootstrap components
import $ from 'jquery';
import Popper from 'popper.js';
import './src/styles/bootstrap/bootstrap.min.css';
import './src/styles/bootstrap/bootstrap.bundle.min.js';
// Global stylesheets
import 'normalize.css/normalize.css';
import './src/styles/global.css';
// Utils
import './src/utils/track.js';

if (typeof window !== 'undefined') {
  // eslint-disable-next-line global-require
  require('smooth-scroll')('a[href*="#"]');
}
